import React, { useEffect, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./AuthModal.module.scss"
import { PatternFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { authModalOpen } from "../../../redux/slice/authModal"
import { useGetSmsMutation, useLoginSmsMutation, useSetNewUserNameMutation } from "../../../redux/api/auth"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import { useGetServiceListMutation } from "../../../redux/api/main"
import { updateServiceList } from "../../../redux/slice/main"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectIsAllowForRegistaration } from "../../../redux/slice/auth"
import { USER_TOKEN_COOKIE } from "../../../utils/constants"
import { getCookie } from "../../../utils/cookies"
import { useCheckTokenMutation } from "../../../redux/api/auth"

interface Props {
  type?: string
  appendFcn?: () => void
}

const AuthModal: React.FC<Props> = ({ type, appendFcn }) => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: `interface` })
  const [getSms] = useGetSmsMutation()
  const [logined] = useLoginSmsMutation()
  const [setNewUserName] = useSetNewUserNameMutation()
  // Шаг модалки авторизации
  const [step, setStep] = useState<number>(1)
  const [btnPhoneDisabled, setBtnPhoneDisabled] = useState<boolean>(true)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [inputName, setInputName] = useState<string | undefined>(undefined)
  const [code, setCode] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()
  const [seconds, setSeconds] = useState(0)
  const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)
  const [isShowError, setIsShowError] = useState<boolean>(false)
  const [countWithErr, setCountWithErr] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [getServiceList] = useGetServiceListMutation()
  const [privacyLink, setPrivacyLink] = useState<string>("https://staq.ru/terms-en.html")
  const token = getCookie(USER_TOKEN_COOKIE)

  const [checkToken] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })

  const currentLang = localStorage.getItem("i18nextLng") || "en"

  const { openModal } = useSelector((state: any) => state.authModal)

  const isAllowForRegistration = useAppSelector(selectIsAllowForRegistaration)

  useEffect(() => {
    setPrivacyLink(`https://staq.ru/terms-${currentLang}.html`)
  }, [currentLang])

  useEffect(() => {
    let interval: any = null
    if (step === 2 && seconds && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec: number) => sec - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [step, seconds])

  const setOpen = () => {
    dispatch(authModalOpen({ flag: false }))
    setStep(1)
  }

  useEffect(() => {
    if (openModal && type) setStep(type === "room" || type === "onlilang" ? 0 : 1)
  }, [openModal])

  const getSmsByPhone = () => {
    setLoading(true)
    setIsErrCode(undefined)
    getSms({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, "") })
      .unwrap()
      .then((resp: any) => {
        if (resp.errorCode === "UserSmsAuthNotFound") {
          setStep(10)
        } else {
          // if (resp.errorCode === "SmsWasSentToTheThisNumber") {
          //   setSeconds(resp?.data?.ttl)
          // }
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err), setLoading(false)
      })
  }

  useEffect(() => {
    if ((isErrCode === 1 || isErrCode === 2) && code && code.length === 4) setIsErrCode(undefined)
    if (isErrCode === 3 && !seconds) setIsErrCode(undefined)
  }, [code])

  const loginedWithCode = () => {
    if (step === 2) {
      if (phoneNumber) {
        if (isErrCode === 3 && phoneNumber) {
          getSmsByPhone()
          setIsErrCode(undefined)
          return
        }
        if (!code || code.length !== 4) {
          setIsErrCode(1)
          setIsShowError(true)
          return
        }
        if (!isErrCode) {
          setLoading(true)
          logined({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, ""), code })
            .unwrap()
            .then((res) => {
              if (!res.errorCode) {
                if (isAllowForRegistration && res?.data?.user?.recently_created) {
                  setStep(3)
                } else {
                  if (appendFcn) appendFcn()
                  setOpen()
                }
              } else {
                if (res.errorCode === "InvalidCode") {
                  setIsErrCode(2)
                  setIsShowError(true)
                  setCountWithErr(res.data.attemptsLeft)
                }
                if (res.errorCode === "LimitOfAttemptsIsExceeded") {
                  setIsErrCode(3)
                  setIsShowError(true)
                  setSeconds(res?.data?.ttl || 0)
                }
              }
              setLoading(false)
            })
            .then(() => {
              // // Обновление списка услуг
              getServiceList()
                .then((result: any) => {
                  dispatch(updateServiceList({ list: result?.data?.data ?? [] }))
                })
                .catch((err: any) => console.log(err))
            })
            .catch((err) => {
              console.log(err, "err")
              setLoading(false)
            })
        }
      }
    }
    if (step === 3) {
      if (!inputName?.length) {
        setIsErrCode(4)
        setIsShowError(true)
        return
      }
      setNewUserName({ name: inputName.trim() }).then(() => {
        checkToken(token)
        if (appendFcn) appendFcn()
        setOpen()
      })
    }
  }

  const onPaste = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const clipboardText = e.clipboardData.getData("Text").replace(/([()\-\s])/g, "")
    let replaceValue = ""

    if (clipboardText.length === 12) {
      if (clipboardText[0] === "+") {
        replaceValue = clipboardText.replace(/\+7/, "")
      }
    } else if (clipboardText.length === 11) {
      if (clipboardText[0] === "8" || clipboardText[0] === "7") {
        // replaceValue = clipboardText
        replaceValue = clipboardText.slice(1, clipboardText.length)
      }
    } else if (clipboardText.length === 10) {
      replaceValue = clipboardText
    }

    setPhoneNumber(`${replaceValue}`)
    return false
  }

  const checkCodeValueAndErrors = (e: any) => {
    if (isErrCode) {
      if (e.target.value.length < 4) {
        setIsShowError(false)
      }
    }
    setCode(e.target.value)
  }  

  return (
    <Modal open={openModal} setOpen={setOpen}>
      <div className={styles.authModal}>
        {step === 1 && (
          <div>
            <div className={`modal-head`}>
              <div className={`modal-title`}>{t("auth")}</div>
              <img src="/img/logo_staq_sm.svg" alt="" />
            </div>
            <div className={`modal-text`}>{t("authText1")}</div>
            <div className={`modal-input`}>
              <div className={`input-label`}>{t("enterPhone")}</div>
              <PatternFormat
                type="tel"
                format="+7 (###) ###-##-##"
                allowEmptyFormatting
                prefix="+"
                data-min="11"
                value={phoneNumber}
                mask="_"
                onValueChange={(num1: any) => {
                  const inputNum = num1.formattedValue
                  setBtnPhoneDisabled((prev: boolean) => {
                    return num1.value.length === 10 ? false : true
                  })
                  setPhoneNumber(`${inputNum}`)
                }}
                disabled={loading}
                onPaste={onPaste}
              />
            </div>
            <div className={`modal-bottom`}>
              {type === "room" && (
                <button type="button" onClick={() => setStep(0)} className={`btn btn_grey btn_sm`}>
                  {t("back")}
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  if (phoneNumber) {
                    getSmsByPhone()
                  }
                }}
                className={`btn`}
                disabled={btnPhoneDisabled}
              >
                {t("next")}
                {loading && <BtnLoader />}
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className={`modal-head`}>
              <div className={`modal-title`}>{t("confirm")}</div>
            </div>
            <div className={`modal-text`}>{t("authText2")}</div>
            <div className={`modal-input ${isErrCode && isShowError ? "error" : ""}`}>
              <div className={`input-label`}>{t("enterCode")}</div>
              <PatternFormat
                format="####"
                allowEmptyFormatting
                mask=""
                placeholder="0000"
                onChange={checkCodeValueAndErrors}
                disabled={seconds === 0 || isErrCode === 3}
              />
              {isShowError && (
                <div className={`error-text`}>
                  {isErrCode === 1
                    ? t("enterCode")
                    : isErrCode === 2
                    ? `${t("incorrectCode")} ${countWithErr} ${t("afterIncorCode")}`
                    : isErrCode === 3
                    ? `${t("limitReachInvalCode")}`
                    : ""}
                </div>
              )}
            </div>
            {seconds === 0 && (
              <>
                <div className={`${styles.authModal__codeEnd}`}>
                  <span>{t("codeTimeEnd")}</span>
                  <button
                    className={styles.authModal__resendBtn}
                    disabled={seconds !== 0}
                    onClick={() => {
                      if (phoneNumber) {
                        getSmsByPhone()
                      }
                    }}
                  >
                    {t("resendCode")}
                  </button>
                </div>
              </>
            )}
            {/* <button
              className={styles.authModal__resendBtn}
              disabled={seconds !== 0}
              onClick={() => {
                if (phoneNumber) {
                  getSmsByPhone()
                }
              }}
            >
              {i18n.language === "cn" ? (
                <>{seconds > 0 ? `${seconds} ${t("after")}` : t("resend")}</>
              ) : (
                <>
                  {t("resend")} {seconds > 0 && `(${t("after")} ${seconds} ${t("sec")})`}
                </>
              )}
              <>
                {t("resend")} {seconds > 0 && `${seconds}`}
              </>
            </button> */}
            <div className={`modal-bottom`}>
              {/*{type === "room" && (*/}
              <button type="button" onClick={() => setStep(1)} className={`btn btn_grey btn_sm`}>
                {t("back")}
              </button>
              {/*)}*/}
              <button
                type="button"
                onClick={() => loginedWithCode()}
                // onClick={() => setStep(3)}
                className={`btn`}
                disabled={
                  (isErrCode && isErrCode !== 3) || (isErrCode === 3 && seconds !== 0) || loading
                }
              >
                {isErrCode === 3
                  ? `${t("resend")} ${
                      seconds > 0
                        ? `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`
                        : ""
                    }`
                  : `${t("confirmBtn")}`}
                {loading && <BtnLoader />}
              </button>
            </div>
          </div>
        )}
        {/* {Окно регистрации} */}
        {step === 3 && (
          <div>
            <div className={`modal-head`}>
              <div className={`modal-title`}>{t("singUp")}</div>
            </div>
            <div className={`modal-text`}>{t("signUpDescr")}</div>
            <div className={`modal-input`}>
              <div className={`input-label`}>{t("yourName")}</div>
              <input
                type="text"
                value={inputName}
                onChange={(e) => {
                  setInputName(e?.target?.value)
                  isErrCode === 4 && setIsErrCode(undefined)
                }}
                placeholder={t("placeholderName")}
                className={`input ${isErrCode === 4 && "error"}`}
              />
              {isErrCode === 4 ? <div className={`error-text error-text_visible`}>{t("signUpError")}</div> : <></>}
            </div>
            <div className={`modal-text`}>
              {t("signUpPrivacyText")}
              <a href={privacyLink} className={"modal-text__link"} target="__blank">{`${t("signUpPrivacyLink")}`}</a>
            </div>
            <div className={`modal-bottom`}>
              <button type="button" onClick={() => setStep(1)} className={`btn btn_grey btn_sm`}>
                {t("back")}
              </button>
              <button type="button" onClick={() => loginedWithCode()} className={`btn`}>
                {t("confirmBtn")}
                {loading && <BtnLoader />}
              </button>
            </div>
          </div>
        )}
        {/* Окошко ошибки */}
        {step === 10 && (
          <div>
            <div className={`modal-head`}>
              <div className={`modal-title`}>{t("numberNotFound")}</div>
            </div>
            <div className={`modal-text`}>{t("authText3")}</div>
            <div className={`modal-bottom`}>
              <button type="button" onClick={() => setStep(1)} className={`btn btn_grey`}>
                {t("back")}
              </button>
            </div>
          </div>
        )}
        {/*  */}
      </div>
    </Modal>
  )
}

export default AuthModal
