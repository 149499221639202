import React, { useState, useEffect } from "react"
import styles from "./NoFindBtn.module.scss"
import NoFindModal from "../Modals/NoFindModal/NoFindModal"
import { useTranslation } from "react-i18next"
import SelectObjModal from "../Modals/SelectObjModal/SelectObjModal"
import { TechnicalObject } from "../../types/orderTypes"
import { useGetTechObjectListMutation, usePostReqForNewProductMutation } from "../../redux/api/main"
import { Form, Formik } from "formik"
import { selectAuth, selectIsAllowNewServiceCategory } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import { useLocation, useParams } from "react-router-dom"
import SuccessModal from "../Modals/SuccessModal/SuccessModal"

interface initialValues {
  technical_object_id?: string
  category_id?: string
  text: string
}

interface Props {
  size?: string
  isLoading?: boolean
}

const NoFindBtn: React.FC<Props> = ({ size, isLoading }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectObjModal, setSelectObjModal] = useState(false)
  const [techObjects, setTechObjects] = useState<TechnicalObject[]>([])
  const [successModal, setSuccessModal] = useState(false)
  const [successModalNum, setSuccessModalNum] = useState<number>(0)
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [getProductInfo] = useGetTechObjectListMutation()
  const [setNewProduct] = usePostReqForNewProductMutation()
  const user = useAppSelector(selectAuth)
  const { pathname } = useLocation()
  const params = useParams()

  const isAllowNewServiceCategory = useAppSelector(selectIsAllowNewServiceCategory)

  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")
  const isProductsPage = pathname.includes("products")
  const isCategoryPage = pathname.includes("category")

  // useEffect(() => {
  //   getProductInfo()
  //     .unwrap()
  //     .then((result: any) => {
  //       setTechObjects(result?.data?.aItems ?? [])
  //     })
  //     .catch((err: any) => console.log(err))
  // }, [])

  const initialValuesForm: initialValues = {
    // technical_object_id: "",
    text: "",
  }
  if (isObjectPage) initialValuesForm.technical_object_id = params.id
  if(isObjectPage && isCategoryPage && isAllowNewServiceCategory) initialValuesForm.category_id = params.category_id
  if (isProductsPage && isAllowNewServiceCategory) initialValuesForm.category_id = params.id

  const submitForm = async (values: initialValues, actions: any) => {
    actions.setSubmitting(true)
    try {
      await setNewProduct(values)
        .unwrap()
        .then((res) => {
          setSuccessModalNum(res?.data?.number ?? 0)
          actions.resetForm()
          setSelectObjModal(false)
          actions.setSubmitting(false)
          setOpenModal(false)
          setSuccessModal(true)
        })
    } catch (e) {
      console.log(e)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <button
        type="button"
        className={`NoFindBtn ${styles.noFindBtn} ${size === "big" ? styles.noFindBtn_big : ""} ${
          isLoading ? "selectBack" : ""
        }`}
        onClick={() => {
          if (!user.token) return
          setOpenModal(true)
        }}
      >
        {isLoading ? "" : t("noFindService")}
      </button>
      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => {
          submitForm(values, actions)
        }}
      >
        <Form>
          <NoFindModal open={openModal} setOpen={setOpenModal} setNextModal={setSelectObjModal} />
          {/* <SelectObjModal
            open={selectObjModal}
            setOpen={setSelectObjModal}
            techObjects={techObjects}
            setBackModal={setOpenModal}
          /> */}
          <SuccessModal
            modalTitle={`${t("cancelOrderApplication")} #${successModalNum} ${t("openOrderPublished")}`}
            modalText={`${t("openOrderComment")}`}
            setOpen={setSuccessModal}
            open={successModal}
          />
        </Form>
      </Formik>
    </>
  )
}

export default NoFindBtn
