import React, { useRef, useEffect } from "react"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import ConditionList from "./ConditionList"
import { TOrderOptions } from "../../../types/orderTypes"

interface IOptionsList {
  optionsItems: TOrderOptions
  setError?: (val: boolean) => void
  optionsRef?: any
}

const OptionsList: React.FC<IOptionsList> = ({ optionsItems, setError, optionsRef }) => {
  useEffect(() => {
      if(optionsRef?.current){
        optionsRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" })
      }
  }, [optionsRef])
  return (
    <>
      {Array.isArray(optionsItems) && optionsItems.length > 0 ? (
        <div ref={optionsRef}>
          {optionsItems.map((el: any, ii) => (
            <React.Fragment key={el.id} >
              <OrderModalCommonBlock el={el} setError={setError}/>
              {el?.conditions?.length > 0 && <ConditionList onlyDate={el?.only_date} maxNumber={el?.max} minNumber={el?.min} elType={el.type} elId={el.id} conditionList={el?.conditions} />}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default OptionsList
