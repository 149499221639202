import React, { useState, useEffect, useRef } from "react"
import styles from "./SearchList.module.scss"
import ProductItem from "../ProductItem/ProductItem"
import SelectObjModal from "../Modals/SelectObjModal/SelectObjModal"
import OrderModal from "../Modals/OrderModal/OrderModal"
import { useGetProductInfoMutation } from "../../redux/api/main"
import { Form, Formik, FormikConfig } from "formik"
import { usePostCreateOrderMutation } from "../../redux/api/content"
import { toBase64 } from "../../utils/helpers"
import SuccessModal from "../Modals/SuccessModal/SuccessModal"
import AuthModal from "../Modals/AuthModal/AuthModal"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { authModalOpen } from "../../redux/slice/authModal"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { addActiveOrdersCount } from "../../redux/slice/auth"

interface Props {
  title?: string
  list: any[]
  isSearchLoading?: boolean
  catId?: string
  searchVal?: string | number
  className?: string
}

const SearchList: React.FC<Props> = ({ title, list, isSearchLoading, catId, searchVal, className }) => {
  const dispatch = useAppDispatch()
  const [createOrder, { isLoading }] = usePostCreateOrderMutation()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { token } = useAppSelector((state) => state.auth)

  const [selectObjModal, setSelectObjModal] = useState(false)
  const [orderModal, setOrderModal] = useState(false)
  const [orderModalLoading, setOrderModalLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successModalNum, setSuccessModalNum] = useState<number>(0)
  const [initialValuesForm, setInitialValuesForm] = useState<{ [key: string]: any }>({})

  const [prodModalInfo, setProdModalInfo] = useState<any>({})

  const [getProductInfo] = useGetProductInfoMutation()

  const { pathname } = useLocation()
  const query = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  const orderModalFcn = (id: string) => {
    setOrderModal(true)
    setOrderModalLoading(true)
    getProductInfo(id)
      .then((result: any) => {
        setOrderModalLoading(false)
        setProdModalInfo(result?.data?.data ?? {})
        if (result?.data?.data?.technicalObjects?.length && !isObjectPage) {
          if (result?.data?.data?.technicalObjects?.length !== 1) {
            setOrderModal(false)
            setTimeout(()=> {
              setSelectObjModal(true)
              getProductInfo(id).then((res: any) => setProdModalInfo(res?.data?.data))
            }, 0)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (!orderModal && !selectObjModal) {
      setInitialValuesForm({})
    }
  }, [orderModal])

  const submitForm = async (values: { [key: string]: any }, actions: any) => {
    const formData = new FormData()
    let key: keyof typeof values
    let arrNum = 0

    for (key in values) {
      if (key !== "count" && key !== "service_id" && key !== "technical_object_id") {
        if (Array.isArray(values[key]) && values[key].length && values[key][0] !== 'information') {
          let numArr = 0
          for (const item of values[key]) {
            formData.append(`options[${arrNum}][id]`, key as string)

            const fileName = item?.name ?? ""
            if (item instanceof Blob) {
              const attachmentType = prodModalInfo?.options.filter((el:any)=>el.id === key)?.[0]?.type
              const file = await toBase64(item)
              //@ts-ignore
              formData.append(`options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}]${attachmentType === "attachment" ? "[data]" : ""}`, file)
              if (attachmentType === "attachment") {
                formData.append(`options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}][filename]`, fileName)
              }
            } else {
              formData.append(`options[${arrNum}][items][${numArr}]`, item)
            }
            numArr++
          }
          arrNum++
        } else if(Array.isArray(values[key]) && values[key][0] && values[key][0] === 'information'){
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][wasShowed]`, values[key][1])
          arrNum++
        } else if (typeof values[key] === "boolean" || (!Array.isArray(values[key]) && values[key])) {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][value]`, values[key])
          arrNum++
        }
      }
    }

    formData.append("service_id", values.service_id)
    formData.append("count", `${values.count}`)
    if (prodModalInfo?.technicalObjects?.length === 1) formData.append("technical_object_id", `${prodModalInfo?.technicalObjects[0]?.id}`)
    if (values.technical_object_id) formData.append("technical_object_id", `${values.technical_object_id}`)
    else if (isObjectPage) formData.append("technical_object_id", `${query.id}`)

    const userToken = token || localStorage.getItem("token")
    if (userToken) {
      actions.setSubmitting(true)
      try {
        await createOrder(formData)
          .unwrap()
          .then((res) => {
            actions.resetForm()
            setOrderModal(false)
            setSelectObjModal(false)
            dispatch(addActiveOrdersCount())
            setSuccessModalNum(res?.data?.number ?? 0)
            setSuccessModal(true)
            actions.setSubmitting(false)
          })
      } catch (e) {
        console.log(e)
        actions.setSubmitting(false)
      }
    } else {
      dispatch(authModalOpen({ flag: true }))
    }
  }
  const skeletonArr = ["", "", "", "", "", "", "", ""]

  return (
    <>
      {!isSearchLoading && !list.length && searchVal ? (
        <p className={styles.nothingContent}>{t("searchServiceNotFound")}</p>
      ) : (
        <div className={`${styles.searchList} searchList-item ${className ? className : ''}`} id={catId ?? ""}>
          {title ? <div className={styles.searchList__title}>{title}</div> : <></>}
          <div className={styles.searchList__wp}>
            {isSearchLoading
              ? skeletonArr.map((el, kk: number) => <ProductItem key={kk} isLoading={true} />)
              : list.map((el: any, kk: number) => (
                  <ProductItem
                    img={el?.photos?.[0]}
                    text={el.name}
                    firstTime={el?.execution_time_from}
                    secondTime={el?.execution_time_to}
                    timeUnit={el?.execution_time_units}
                    ordered={false}
                    onClick={() => orderModalFcn(el.id)}
                    description={el?.description}
                    key={kk}
                  />
                ))}
          </div>
        </div>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => {
          submitForm(values, actions)
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            {prodModalInfo?.technicalObjects?.length ? (
              <SelectObjModal
                open={selectObjModal}
                setOpen={setSelectObjModal}
                techObjects={prodModalInfo.technicalObjects}
                setBackModal={setOrderModal}
                isLoading={isLoading}
              />
            ) : (
              <></>
            )}

            <OrderModal
              open={orderModal}
              setOpen={setOrderModal}
              modalInfo={prodModalInfo}
              setInitialValuesForm={setInitialValuesForm}
              setSelectObjModal={setSelectObjModal}
              isLoading={isLoading}
              orderModalLoading={orderModalLoading}
            />
            <SuccessModal
              modalTitle={`${t("cancelOrderApplication")} #${successModalNum} ${t("openOrderPublished")}`}
              modalText={`${t("openOrderComment")}`}
              setOpen={setSuccessModal}
              open={successModal}
            />
            {!isObjectPage ? <AuthModal appendFcn={handleSubmit} /> : ""}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SearchList
