import React, { useEffect, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Header from "../Header"
import Search from "../Search/Search"
import OrdersLine from "../OrdersLine/OrdersLine"
import ServicesList from "../ServicesList/ServicesList"
import SearchList from "../SearchList/SearchList"
import Footer from "../Footer"
import AuthModal from "../Modals/AuthModal/AuthModal"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"
import { useTranslation } from "react-i18next"
import { useGetProductsListMutation, useGetServiceListMutation } from "../../redux/api/main"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { updateServiceList } from "../../redux/slice/main"
import { selectAuth, setIsAllowRegistration, setIsAllowProposeNewServiceMain } from "../../redux/slice/auth"
import { authModalOpen } from "../../redux/slice/authModal"
import { useGetSettingsMutation } from "../../redux/api/auth"
import RatingModal from "../Modals/RatingModal/RatingModal"
import { useLazyPostServiceOrdersQuery } from "../../redux/api/content"

interface Props {}

const MainLayout: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const user = useAppSelector(selectAuth)
  const { serviceListLoading } = useAppSelector((state) => state.main)
  const [getProductsList, { isLoading }] = useGetProductsListMutation()
  const [getServiceList] = useGetServiceListMutation()
  const [searchVal, setSearchVal] = useState<string | number>("")
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const [getSettings] = useGetSettingsMutation()
  const [ordersWithotShowModal, setOrdersWithotShowModal] = useState<any>(null)

  const [serviceList, setServiceList] = useState<any[]>([])
  // Модалка аутентификации
  const [authModal, setAuthModal] = useState(false)
  // Тип модалки аутентификации
  const [authModalType, setAuthModalType] = useState("")

  const [getActiveOrders] = useLazyPostServiceOrdersQuery()

  // получение списка заказов для показа модалки отзыва
  useEffect(() => {
    if (user.token) {
      getActiveOrders({ isActive: false })
        .unwrap()
        .then((res) => {
          setOrdersWithotShowModal(res?.data?.aItems?.filter((el: any) => el.reviewModalShown !== true))
        })
    }
  }, [user])
  useEffect(() => {
    // Получание списка услуг
    getServiceList()
      .then((result: any) => {
        dispatch(updateServiceList({ list: result?.data?.data ?? [] }))
      })
      .catch((err: any) => console.log(err))
    // получение параметра для регистрации
    getSettings().then((res) => {
      dispatch(setIsAllowRegistration(res))
      dispatch(setIsAllowProposeNewServiceMain(res))
    })
  }, [currentLang])

  useEffect(() => {
    if (!authModal) {
      setAuthModalType("")
    }
  }, [authModal])

  useEffect(() => {
    setIsSearchLoading(true)
    if (searchVal === "") setIsSearchLoading(false)

    if (typeof searchVal === "string" && searchVal.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        getProductsList({ keywords: searchVal })
          .unwrap()
          .then((res) => {
            setIsSearchLoading(false)
            setServiceList(res.data.aItems)
          })
          .catch((err) => console.log(err))
      }, 500)

      return () => clearTimeout(timeoutId)
    }
  }, [searchVal])

  return (
    <>
      {serviceListLoading ? (
        <>
          <Header isLoading={serviceListLoading} />
          <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
          <OrdersLine isLoading={serviceListLoading} />
          <ServicesList setAuthModal={setAuthModal} setAuthModalType={setAuthModalType} />
        </>
      ) : (
        <>
          {searchVal ? (
            <TitleBack title={t("search")} setSearchVal={setSearchVal} searchVal={searchVal} />
          ) : (
            <Header isLoading={serviceListLoading} />
          )}
          <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
          {searchVal ? (
            <SearchList list={serviceList} isSearchLoading={isSearchLoading} searchVal={searchVal} />
          ) : (
            <>
              {user.token && <OrdersLine />}
              <ServicesList setAuthModal={setAuthModal} setAuthModalType={setAuthModalType} />
            </>
          )}
          <Footer showBtn={!!searchVal} />
          <AuthModal type={authModalType} />
          <LanguageModal />
          {user.token && ordersWithotShowModal?.length > 0 && <RatingModal openedOrder={ordersWithotShowModal[0].id} />}
        </>
      )}
    </>
  )
}

export default MainLayout
