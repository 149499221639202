import React, { useEffect, useState, useRef } from "react"
import styles from "./RoomLayout.module.scss"
import SearchList from "../SearchList/SearchList"
import { useGetRoomSubcatListMutation, useGetRoomServicesMutation } from "../../redux/api/main"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  currentObject,
  updateSubCatsObject,
  resetSubCatsLists,
  setServicesInCategory,
  resetServicesInCategory,
} from "../../redux/slice/object"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperOptions } from "swiper/types/swiper-options"
import { FreeMode } from "swiper/modules"
import ProductItem from "../ProductItem/ProductItem"

interface Props {}

const RoomCategoryServices: React.FC<Props> = () => {
  const [isLoadingList, setIsLoadingList] = useState<any>(false)
  const [servicesFromCategory, setServicesFromCategory] = useState<any>(null)
  const [searchedList, setSearchedList] = useState<any[]>([])
  const [numActiveLink, setNumActiveLink] = useState<null | number>(0)
  const serviceItemsContainer = useRef<null | NodeListOf<HTMLElement>>(null)
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const swiperRef = useRef<any>(null)


  // Дабы показывать прелоадер, а не то, что нет услуш для объектов
  const firstLoad = useRef<any>(true)

  const scrollTrigger = () => {
    const scrollMenu = document.querySelector<HTMLElement>(`.${styles.roomLayout__cats}`)
    const windowWidth = window.innerWidth

    const serviceNodeList = serviceItemsContainer.current
    const offset = windowWidth > 640 ? 180 : 120

    serviceNodeList?.forEach((el, i) => {
      const sw = swiperRef.current.swiper
      // - 1 слайд при перключении
      if (window.scrollY > el.offsetTop + offset - 5 && window.scrollY < el.offsetTop + el.offsetHeight + offset) {
        setNumActiveLink(i)
        sw.slideTo(i)
      } else if (
        window.scrollY < serviceNodeList[0].offsetTop + offset ||
        window.scrollY >
          serviceNodeList[serviceNodeList.length - 1].offsetTop +
            serviceNodeList[serviceNodeList.length - 1].offsetHeight +
            offset
      )
        setNumActiveLink(0)
    })

    //меню с ссылками
    if (scrollMenu) {
      const searchBlock = document.querySelector<HTMLDivElement>(`.${styles.roomLayout__searchBlock}`)
      const valuePx = windowWidth <= 640 ? 231 - window.scrollY : 332 - window.scrollY
      const scrollMenuInsideBlock = scrollMenu.querySelector<HTMLDivElement>("div")
      if ((windowWidth <= 640 && valuePx <= 107) || (windowWidth > 640 && valuePx <= 150)) {
        scrollMenu.style.top = `${windowWidth <= 640 ? 56 : 130}px`
        scrollMenu.style.borderBottom = "1px solid #b3b8bb"
        if (scrollMenuInsideBlock) {
          windowWidth > 640
            ? (scrollMenuInsideBlock.style.borderRadius = "24px 24px 0 0")
            : (scrollMenuInsideBlock.style.borderRadius = "16px 16px 0 0")
        }
        if (searchBlock) {
          searchBlock.classList.add(`${styles.roomLayout__searchBlock_hidden}`)
        }
        return
      } else {
        scrollMenu.style.top = `${valuePx}px`
        scrollMenu.style.borderBottom = "none"
        if (searchBlock) {
          searchBlock.classList.remove(`${styles.roomLayout__searchBlock_hidden}`)
        }
        if (scrollMenuInsideBlock) {
          scrollMenuInsideBlock.style.borderRadius = "0 0 0 0"
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", scrollTrigger)
    document.addEventListener("resize", scrollTrigger)
    scrollTrigger()

    return () => {
      document.removeEventListener("scroll", scrollTrigger)
      document.removeEventListener("resize", scrollTrigger)
      dispatch(resetSubCatsLists())
      setServicesFromCategory(null)
    }
  }, [])

  const [getRoomServices] = useGetRoomServicesMutation()
  const { serviceCategories, subCatsObject } = useAppSelector(currentObject)

  const dispatch = useAppDispatch()
  const queries: any = useParams()

  const getList = () =>
    getRoomServices({ technical_object_id: queries?.id ?? "", category_id: queries?.category_id ?? "" })

  useEffect(() => {
    setIsLoadingList(true)
    getList()
      .then((result: any) => {
        dispatch(updateSubCatsObject(result ?? []))
      })
      .catch((er: any) => console.log(er.message))
      .finally(() => setIsLoadingList(false))

    return () => {
      dispatch(resetServicesInCategory())
    }
  }, [])

  useEffect(() => {
    if (subCatsObject?.length > 0 && (!serviceItemsContainer.current || serviceItemsContainer.current.length === 0))
      serviceItemsContainer.current = document.querySelectorAll<HTMLElement>(`.searchList-item`)
  })

  const scrollToItem = (num: number) => {
    const offset = window.innerWidth > 640 ? 180 : 120

    if (serviceItemsContainer.current) {
      const scrollTo = serviceItemsContainer.current[num].offsetTop + offset
      window.scrollTo(0, scrollTo)
    }
  }

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    modules: [FreeMode],
    freeMode: true,
  }

  useEffect(() => {
    if (subCatsObject.length > 0) {
      const servicesArr: Array<any> = []
      subCatsObject.forEach((el: any) => {
        el.services.forEach((service: any) => servicesArr.push(service))
      })
      dispatch(setServicesInCategory(servicesArr))
    } else if (subCatsObject.length < 1) {
      const needService = serviceCategories.find((el: any) => el.id === queries?.category_id)
      needService ? setServicesFromCategory(needService) : setServicesFromCategory(null)
      dispatch(setServicesInCategory(servicesFromCategory?.services))
    }
  }, [subCatsObject, serviceCategories, queries])

  useEffect(() => {
    dispatch(setServicesInCategory(servicesFromCategory?.services))
  }, [servicesFromCategory])

  return (
    <>
      {searchedList.length < 1 && (
        <>
          <div style={{ display: subCatsObject?.length ? "block" : "none" }} className={styles.roomLayout__cats}>
            <div className={styles.roomLayout__catsWp}>
              <div className={styles.roomLayout__catsWrap}>
                <Swiper {...swiperParams} ref={swiperRef}>
                  {subCatsObject?.map((el: any, kk: number) => (
                    <SwiperSlide
                      onClick={() => scrollToItem(kk)}
                      key={el.id}
                      className={`${styles["roomLayout__catsWrap-anchor"]} ${
                        numActiveLink === kk ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                      }`}
                    >
                      <a>{el.name}</a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          <div className={styles.roomLayout__list}>
            {subCatsObject?.length > 0 ? (
              subCatsObject?.map((subCat: any, kk: number) => (
                <SearchList
                  isSearchLoading={isLoadingList}
                  key={subCat?.id}
                  title={subCat?.name ?? ""}
                  list={subCat?.services}
                  catId={subCat?.id}
                  className={styles.roomLayout__searchList}
                />
              ))
            ) : servicesFromCategory ? (
              <SearchList
                isSearchLoading={isLoadingList}
                key={servicesFromCategory?.id}
                title={servicesFromCategory?.name ?? ""}
                list={servicesFromCategory?.services}
                catId={servicesFromCategory?.id}
                className={styles.roomLayout__searchList}
              />
            ) : firstLoad?.current ? (
              <>
                <ProductItem isLoading={true} />
                <ProductItem isLoading={true} />
                <ProductItem isLoading={true} />
              </>
            ) : (
              <div className={styles.roomLayout__empty}>{t("emptyObject")}</div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default RoomCategoryServices
