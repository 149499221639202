import React, { useEffect, useMemo, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Search from "../Search/Search"
import SearchList from "../SearchList/SearchList"
import Footer from "../Footer"
import { useParams } from "react-router-dom"
import { useGetProductsListMutation, useGetServiceInfoMutation } from "../../redux/api/main"
import { updateProductsList } from "../../redux/slice/products"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  selectIsAllowProposeNewServiceMain,
  updateIsAllowNewServiceCategory,
  selectIsAllowNewServiceCategory,
} from "../../redux/slice/auth"

interface Props {}

const ProductsLayout: React.FC<Props> = () => {
  const [searchVal, setSearchVal] = useState<string | number>("")
  const [pageTitle, setPageTitle] = useState<string>("")
  // const [isAllowNewServiceCategory, setIsAllowNewServiceCategory] = useState<boolean>(false)

  const [getServiceInfo] = useGetServiceInfoMutation()
  const [getProductsList, { isLoading }] = useGetProductsListMutation()
  const dispatch = useAppDispatch()

  const queries = useParams()

  const { productsList, productsListLoading } = useAppSelector((state) => state.products)
  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const isAllowNewServiceCategory = useAppSelector(selectIsAllowNewServiceCategory)

  useEffect(() => {
    // Получание списка услуг
    getProductsList({ categories: [queries?.id] ?? "" })
      .then((result: any) => {
        dispatch(updateProductsList({ list: result?.data?.data?.aItems ?? [] }))
      })
      .catch((err: any) => console.log(err))
    getServiceInfo(queries?.id ?? "")
      .then((result: any) => {
        setPageTitle(result?.data?.data?.name ?? "")
        dispatch(updateIsAllowNewServiceCategory(result?.data?.data?.is_allow_prorose_new_service))
        // setIsAllowNewServiceCategory(result?.data?.data?.is_allow_prorose_new_service)
      })
      .catch((err: any) => console.log(err))

    window.scrollTo(0, 0)
  }, [])

  const resultSearchList = useMemo(
    () => productsList.filter(({ name }: any) => name?.toLowerCase().includes(String(searchVal).toLowerCase())),
    [searchVal, productsList],
  )

  return (
    <>
      <TitleBack title={pageTitle} />
      <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={isLoading} />
      <SearchList list={resultSearchList} isSearchLoading={isLoading} searchVal={searchVal} />
      <Footer showBtn={isAllowNewServiceCategory || isAllowProposeNewServiceMain} />
    </>
  )
}

export default ProductsLayout
