import React, { Fragment, useEffect, useRef, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./OrderModal.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import OrderModalBlock from "./OrderModalBlock"
import CheckBoxRow from "../../Assets/CheckBoxRow/CheckBoxRow"
import { useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useDropzone } from "react-dropzone"
import { SwiperOptions } from "swiper/types/swiper-options"
import { useTranslation } from "react-i18next"
import { BACKEND_API_URL } from "../../../utils/constants"
import { IOrder } from "../../../types/orderTypes"
import { NumericFormat } from "react-number-format"
import { useLocation, useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import SwipeLine from "../../Modal/SwipeLine"
import { declensionNumber, getFileIco, timeUnitsForLang } from "../../../utils/helpers"
import OrderModalSkeleton from "./OrderModalSkeleton"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import FileDropzone from "../../Assets/FileDropzone/FileDropzone"
import { useAppSelector } from "../../../hooks"
import OrderModalPhotoBlock from "./OrderModalPhotoBlock"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import OptionsList from "./OptionsList"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalInfo: IOrder
  setInitialValuesForm: React.Dispatch<React.SetStateAction<any>>
  setSelectObjModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  orderModalLoading?: boolean
}

const OrderModal: React.FC<Props> = ({
  open,
  setOpen,
  modalInfo,
  setInitialValuesForm,
  setSelectObjModal,
  isLoading,
  orderModalLoading,
}) => {
  const swipedModal: any = useRef(null)
  const [isHaveTechObj, setIsHaveTechObj] = useState(false)
  const [isError, setIsError] = useState(false)
  const { values, submitForm, setFieldValue, isSubmitting } = useFormikContext<{ [key: string]: any }>()

  const object = useAppSelector((state) => state.object.technicalObject)

  const currentLang = localStorage.getItem("i18nextLng")
  let currentTimeUnit =
    timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
      modalInfo.execution_time_units as "minutes" | "hours" | "days"
    ]

  if (currentLang === "ru") {
    const firstTime = modalInfo.execution_time_from
    const secondTime = modalInfo.execution_time_to

    if (modalInfo.execution_time_units === "hours") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["час", "часа", "часов"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["часа", "часов", "часов"])
      }
    }
    if (modalInfo.execution_time_units === "days") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["день", "дня", "дней"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
      }
    }
  }

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  // Инпут файл
  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFieldValue(`${inputRef.current.name}`, [
        ...values[inputRef.current.name],
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
  })

  // Превьюшки
  const thumbs = (id: string) =>
    values[id]?.map((file: any) => (
      <div className={styles.photoBlock__preview} key={file.name}>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          onClick={() =>
            setFieldValue(
              id,
              values[id].filter((el: any) => el.path !== file.path),
            )
          }
          className={styles.photoBlock__close}
        >
          <img src="/img/close.svg" alt="" />
        </button>
      </div>
    ))

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    modules: [Pagination],
    pagination: {
      type: "bullets",
      clickable: true,
    },
  }

  const { pathname } = useLocation()
  const params = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  useEffect(() => {
    if (modalInfo?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    setInitialValuesForm(createInitialValues(modalInfo?.options))
  }, [modalInfo])

  const newInitVal: { [key: string]: any } = {}

  const createInitialValues = (options: any) => {
    options &&
      options.forEach((option: any, i: number) => {
        if (option.type === "text") newInitVal[option.id] = ""
        if (option.type === "boolean") newInitVal[option.id] = null
        if (option.type === "checkbox") newInitVal[option.id] = null
        if (option.type === "number") newInitVal[option.id] = null
        if (option.type === "datetime") newInitVal[option.id] = undefined
        if (option.type === "list") newInitVal[option.id] = []
        if (option.type === "photo") newInitVal[option.id] = []
        if (option.type === "attachment") newInitVal[option.id] = []
        if (option.type === "information") newInitVal[option.id] = ['information', 0]

        if (option.conditions.length > 0) {
          option.conditions.forEach((condition: any) => createInitialValues(condition.options))
        }
      })

    newInitVal.service_id = modalInfo.id
    newInitVal.count = 1

    if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = params?.id

    return newInitVal
  }

  const nextOrSubmitStep = () => {
    submitForm()
  }

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  return (
    <Modal open={open} setOpen={setOpen} isOrder={true} refContent={swipedModal}>
      <form>
        <div className={styles.orderModal}>
          <SwipeLine swipedModal={swipedModal} setOpen={setOpen} />
          <div
            className={styles.orderModal__top}
            style={{ display: !orderModalLoading && !modalInfo?.photos?.length ? "none" : "block" }}
          >
            <Swiper {...swiperParams}>
              {orderModalLoading ? (
                <SwiperSlide className={`selectBack`}></SwiperSlide>
              ) : (
                modalInfo?.photos?.map((el: string, kk: number) => (
                  <SwiperSlide key={kk}>
                    <img src={BACKEND_API_URL + "/public/photo/" + el} alt="Preview" />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </div>
          <div
            className={`
            ${styles.orderModal__body} 
            ${orderModalLoading ? styles.orderModal__body_load : ""} 
            ${!orderModalLoading && !modalInfo?.options?.length ? styles.orderModal__roundedTop : ""}
          `}
          >
            {orderModalLoading ? (
              <OrderModalSkeleton />
            ) : (
              <>
                {(!!modalInfo?.technicalObjects?.length || isObjectPage) && (
                  <div className={`${styles.orderModal__object}`}>
                    <div className={`${styles.orderModal__objectIco}`}>
                      <img src="/img/object_ico.svg" alt="" />
                    </div>
                    <div className={`${styles.orderModal__objectText}`}>
                      <span>{t("selectedObject")}</span>
                      <p>
                        {isObjectPage
                          ? object.name
                          : values?.technical_object_id
                          ? modalInfo?.technicalObjects?.filter((el) => el.id === values.technical_object_id)?.[0]?.name
                          : modalInfo?.technicalObjects?.[0]?.name}
                      </p>
                    </div>
                  </div>
                )}
                {!!modalInfo?.description && (
                  <div className={`modal-text`} dangerouslySetInnerHTML={{ __html: modalInfo?.description ?? "" }} />
                )}
                {modalInfo?.options?.length > 0 && (
                  <OptionsList optionsItems={modalInfo?.options} setError={setErrorHandler} />
                )}

                <div className={styles.orderModal__submitBlock}>
                  <div className={styles.orderModal__submitBlockHead}>
                    <h5>{modalInfo?.name}</h5>
                    <p>
                      {(() => {
                        if (!modalInfo.execution_time_from && !modalInfo.execution_time_to) {
                          return ""
                        } else if (modalInfo.execution_time_from && modalInfo.execution_time_to) {
                          return (
                            <>
                              {modalInfo.execution_time_from}-{modalInfo.execution_time_to} {currentTimeUnit}
                            </>
                          )
                        } else if (modalInfo.execution_time_from && !modalInfo.execution_time_to) {
                          return (
                            <>
                              {t("from")} {modalInfo.execution_time_from} {currentTimeUnit}
                            </>
                          )
                        } else if (!modalInfo.execution_time_from && modalInfo.execution_time_to) {
                          return (
                            <>
                              {t("to")} {modalInfo.execution_time_to} {currentTimeUnit}
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                  {!orderModalLoading && (
                    <div className={styles.orderModal__submitBlockBottom}>
                      {modalInfo?.is_multiorder ? (
                        <div className={styles.orderModal__calc}>
                          <button
                            type="button"
                            className={styles.orderModal__calcPlus}
                            onClick={() => setFieldValue("count", values.count - 1)}
                            disabled={values.count < 2}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M12 8L4 8"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div className={styles.orderModal__calcNum}>{values.count}</div>
                          <button
                            type="button"
                            className={styles.orderModal__calcPlus}
                            onClick={() => setFieldValue("count", values.count + 1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8 4L8 12"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 8L4 8"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : null}
                      {modalInfo?.service_action_name ? (
                        <button
                          onClick={nextOrSubmitStep}
                          type="button"
                          className={`btn`}
                          disabled={isLoading || isError}
                        >
                          {modalInfo?.service_action_name}
                          {isSubmitting && <BtnLoader />}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OrderModal
